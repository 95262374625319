import {
  ILoyaltyRewardsLedgerEntryDatastore,
  LoyaltyRewardsLedgerEntryDto,
  loyaltyRewardsLedgerEntryQueryType
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class LoyaltyRewardsLedgerEntryDatastoreRestClient implements
  ILoyaltyRewardsLedgerEntryDatastore {

  public addAsync(
    loyaltyRewardTaskDto: LoyaltyRewardsLedgerEntryDto
  ): Promise<LoyaltyRewardsLedgerEntryDto> {
    return Promise.reject("Not implemented");
  }

  public findAsync(query: loyaltyRewardsLedgerEntryQueryType) {
    return new Promise<{
      dtos: LoyaltyRewardsLedgerEntryDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: LoyaltyRewardsLedgerEntryDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              config.apiServer +
              "/administrators/loyalty-rewards/ledger-entries",
              query
            )
          );

          response.dtos = response.dtos.map(
            (dto) => new LoyaltyRewardsLedgerEntryDto(dto)
          );
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number): Promise<LoyaltyRewardsLedgerEntryDto> {
    return Promise.reject("Not implemented");
  }

  public removeAsync(id: number): Promise<void> {
    return Promise.reject("Not implemented");
  }

  public updateAsync(
    loyaltyRewardsTasksDto: LoyaltyRewardsLedgerEntryDto
  ): Promise<LoyaltyRewardsLedgerEntryDto> {
    return Promise.reject("Not implemented");
  }
}
