import styles from "./Help.module.scss";

import cn from "classnames";
import React, {
  Fragment,
  useEffect,
  Component
} from "react";
import {
  createContext,
  ReactNode,
  useContext,
  useState
} from "react";
import {
  Popover,
  PopoverBody,
  PopoverHeader
} from "reactstrap";
import {
  FontAwesomeExtendedIcon
} from "../font-awesome-extended/FontAwesomeExtendedIcon";

// At times the Popover renders and errors out if the target isn't present.
//   This bound swallows up errors in production.
class ErrorBoundary extends Component<unknown, { hasError: boolean; }> {
  constructor(props: unknown) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) { }

  render() {
    if (!this.state.hasError) { return this.props.children; }

    return null;
  }
}

export function useDelayedHelpRefresh() {
  const help = useContext(helpContext);
  const [showDelayedHelp, setShowDelayedHelp] = useState(false);
  const delayedHelpRefresh = () => {
    const showHelpIndicators = help.showHelpIndicators;

    if (showHelpIndicators) {
      help.toggleHelpIndicators();
      // Show help again after a setTimeout. Used in useEffect.
      setShowDelayedHelp(true);
    }
  };

  // Handles showing help after a short delay
  useEffect(
    () => {
      if (showDelayedHelp) {
        const timer = setTimeout(
          () => {
            help.toggleHelpIndicators();
            setShowDelayedHelp(false);
          },
          10
        );

        return () => clearTimeout(timer);
      }

      return;
    },
    [help, showDelayedHelp]
  );

  return delayedHelpRefresh;
}

export const helpContext = createContext({
  helpDetailsToExpand: "",
  toggleHelpExpansion: (id: string) => { return; },
  showHelpIndicators: false,
  toggleHelpIndicators: () => { return; }
});

export function HelpProvider(props: { children?: ReactNode }) {
  const [showHelpIndicators, setShowHelpIndicators] = useState(false);
  const [helpDetailsToExpand, setHelpDetailsToExpand] = useState("");

  function toggleHelpExpansion(id: string) {
    if (id !== helpDetailsToExpand) {
      setHelpDetailsToExpand(id);
    } else {
      setHelpDetailsToExpand("");
    }
  }

  function toggleHelpIndicators() {
    setShowHelpIndicators(!showHelpIndicators);
    setHelpDetailsToExpand("");
  }

  return (
    <helpContext.Provider
      value={{
        helpDetailsToExpand,
        toggleHelpExpansion,
        showHelpIndicators,
        toggleHelpIndicators
      }}
    >
      {props.children}
    </helpContext.Provider>
  );
}

// HACK(jc): Had issues with interpreter not finding `props`
//   so it was changed to `popoverProps`
export function HelpPopover(
  props: {
    target: string,
    title: string,
    text: string,
    className?: string,
    placement?: popoverPlacement
  }
) {
  const _helpContext = useContext(helpContext);
  const [refreshPosition, setRefreshPosition] = useState(false);
  const [targetPosition, setTargetPosition] =
    useState<ClientRect | DOMRect | undefined>(undefined);
  const isExpanded = _helpContext.helpDetailsToExpand === props.target;

  // Couldn't clone for some reason.
  function extractRectInfo(rect: ClientRect | DOMRect) {
    return {
      bottom: rect.bottom,
      height: rect.height,
      left: rect.left,
      right: rect.right,
      top: rect.top,
      width: rect.width,
      x: (rect as DOMRect).x || undefined,
      y: (rect as DOMRect).y || undefined
    };
  }

  // Check if position refresh is needed.
  useEffect(() => {
    if (!_helpContext.showHelpIndicators) { return; }

    function haveTargetPositionsChanged() {
      const targetElement = document.getElementById(props.target);

      if (!targetElement) { return; }

      const currentTargetPosition: ClientRect | DOMRect = extractRectInfo(
        targetElement.getBoundingClientRect()
      );

      if (targetPosition) {
        for (const prop of Object.keys(currentTargetPosition)) {
          if (
            !Object.hasOwnProperty.call(currentTargetPosition, prop)
          ) {
            continue;
          }

          if (
            targetPosition[prop] &&
            targetPosition[prop] !== currentTargetPosition[prop]
          ) {
            setTargetPosition(currentTargetPosition);
            setRefreshPosition(!refreshPosition);
            break;
          }
        }
      } else {
        setTargetPosition(currentTargetPosition);
      }
    }

    const timer = setTimeout(() => {
      haveTargetPositionsChanged();
    }, 500);

    return () => (clearTimeout(timer));
  });

  return (
    _helpContext.showHelpIndicators ?
      <ErrorBoundary>
        <Popover
          key={`
            question_mark_${props.target}
            ${isExpanded && "_expanded"}
            ${refreshPosition && "_refresh"}
          `}
          target={props.target}
          isOpen={true}
          className={cn(
            "help-popover bg-help",
            props.className,
            { [styles.helpPopoverExpanded]: isExpanded }
          )}
          placement={props.placement || "auto"}
          onClick={() => {
            _helpContext.toggleHelpExpansion(props.target);
          }}
        >
          {
            isExpanded ?

              <Fragment>
                <PopoverHeader tag="div">{props.title}</PopoverHeader>
                <PopoverBody>{props.text}</PopoverBody>
              </Fragment> :

              <PopoverBody>
                <FontAwesomeExtendedIcon icon="question" fixedWidth={true} />
              </PopoverBody>
          }
        </Popover>
      </ErrorBoundary> :
      null
  );
}

export function HelpToggle() {
  const _helpContext = useContext(helpContext);

  return (
    <button
      type="button"
      className={`
        btn btn-link nav-link p-0
        d-print-none ${styles.helpToggleButton}
      `}
      aria-hidden={true}
      onClick={_helpContext.toggleHelpIndicators}
    >
      <FontAwesomeExtendedIcon
        className="mr-0 mr-sm-2"
        icon={["far", "question-circle"]}
        size="lg"
        fixedWidth={true}
      />
      <span className="d-none d-sm-inline">
        Help
      </span>
    </button>
  );
}

type popoverPlacement = "auto-start" | "auto" | "auto-end" | "top-start" |
  "top" | "top-end" | "right-start" | "right" | "right-end" | "bottom-end" |
  "bottom" | "bottom-start" | "left-end" | "left" | "left-start";
