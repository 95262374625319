import {
  ClientDto,
  clientQueryType,
  IClientDatastore
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class ClientDatastoreRestClient implements IClientDatastore {
  public addAsync(clientDto: ClientDto) {
    return new Promise<ClientDto>(async (resolve, reject) => {
      try {
        const newClientDto = await request<ClientDto>(
          prepareUrl(`${config.apiServer}/administrators/clients`),
          {
            body: clientDto,
            method: "POST"
          }
        );

        resolve(new ClientDto(newClientDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public findAsync(query: clientQueryType) {
    return new Promise<{
      dtos: ClientDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: ClientDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              `${config.apiServer}/administrators/clients`,
              query
            )
          );

          response.dtos = response.dtos.map((dto) => (new ClientDto(dto)));
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number) {
    return new Promise<ClientDto>(async (resolve, reject) => {
      try {
        const clientDto = await request<ClientDto>(
          prepareUrl(
            `${config.apiServer}/administrators/clients/${id}`
          )
        );
        resolve(new ClientDto(clientDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public removeAsync(id: number) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await request(
          prepareUrl(
            `${config.apiServer}/administrators/clients/${id}`
          ),
          { method: "DELETE" }
        );

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAsync(clientDto: ClientDto) {
    return new Promise<ClientDto>(async (resolve, reject) => {
      try {
        const updatedClientDto = await request<ClientDto>(
          prepareUrl(
            `${config.apiServer}/administrators/clients/${clientDto.id}`
          ),
          {
            body: clientDto,
            method: "PATCH"
          }
        );

        resolve(new ClientDto(updatedClientDto));
      } catch (error) {
        reject(error);
      }
    });
  }
}
