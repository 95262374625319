import {
  ILoyaltyRewardsTasksDatastore,
  LoyaltyRewardsTasksDto,
  loyaltyRewardsTasksQueryType
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class LoyaltyRewardTaskDatastoreRestClient implements
  ILoyaltyRewardsTasksDatastore {
  public addAsync(loyaltyRewardTaskDto: LoyaltyRewardsTasksDto) {
    return new Promise<LoyaltyRewardsTasksDto>(async (resolve, reject) => {
      try {
        const newLoyaltyRewardTaskDto =
          await request<LoyaltyRewardsTasksDto>(
            prepareUrl(
              config.apiServer +
              "/administrators/loyalty-rewards/rewards-and-tasks/"
              ),
            {
              body: loyaltyRewardTaskDto,
              method: "POST"
            }
          );

        resolve(new LoyaltyRewardsTasksDto(newLoyaltyRewardTaskDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public findAsync(query: loyaltyRewardsTasksQueryType) {
    return new Promise<{
      dtos: LoyaltyRewardsTasksDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: LoyaltyRewardsTasksDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              config.apiServer +
              "/administrators/loyalty-rewards/rewards-and-tasks/",
              query
            )
          );

          response.dtos = response.dtos.map((dto) => (new LoyaltyRewardsTasksDto(dto)));
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number) {
    return new Promise<LoyaltyRewardsTasksDto>(async (resolve, reject) => {
      try {
        const loyaltyRewardsTasksDto = await request<LoyaltyRewardsTasksDto>(
          prepareUrl(
            config.apiServer +
              "/administrators/loyalty-rewards/rewards-and-tasks/" +
              id
          )
        );
        resolve(new LoyaltyRewardsTasksDto(loyaltyRewardsTasksDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public removeAsync(id: number) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await request(
          prepareUrl(
            config.apiServer +
              "/administrators/loyalty-rewards/rewards-and-tasks/" +
              id
          ),
          { method: "DELETE" }
        );

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAsync(loyaltyRewardsTasksDto: LoyaltyRewardsTasksDto) {
    return new Promise<LoyaltyRewardsTasksDto>(async (resolve, reject) => {
      try {
        const updatedLoyaltyRewardsTasksDto =
          await request<LoyaltyRewardsTasksDto>(
            prepareUrl(
              config.apiServer +
              "/administrators/loyalty-rewards/rewards-and-tasks/" +
              loyaltyRewardsTasksDto.id
            ),
            {
              body: loyaltyRewardsTasksDto,
              method: "PATCH"
            }
          );

        resolve(new LoyaltyRewardsTasksDto(updatedLoyaltyRewardsTasksDto));
      } catch (error) {
        reject(error);
      }
    });
  }
}
