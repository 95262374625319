import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from "react";
import { getTimes } from "suncalc";

import { getLocation } from "../common/util";

export const isSystemThemeDarkMode = window.matchMedia(
  "(prefers-color-scheme: dark)"
).matches;
export const isSystemThemeLightMode = window.matchMedia(
  "(prefers-color-scheme: light)"
).matches;
export const isSystemThemeNotSpecified = window.matchMedia(
  "(prefers-color-scheme: no-preference)"
).matches;
export const isSystemThemeSupported = isSystemThemeDarkMode ||
  isSystemThemeLightMode || isSystemThemeNotSpecified;

// https://www.timeanddate.com/
// Index is month but zero based
// Average world sun rise and sun set times by month
const sunTimeFramesByMonth = [
  { sunRise: { hour: 7, minute: 4 }, sunSet: { hour: 17, minute: 55 } },
  { sunRise: { hour: 6, minute: 53 }, sunSet: { hour: 18, minute: 12 } },
  { sunRise: { hour: 6, minute: 22 }, sunSet: { hour: 18, minute: 28 } },
  { sunRise: { hour: 5, minute: 58 }, sunSet: { hour: 18, minute: 42 } },
  { sunRise: { hour: 5, minute: 36 }, sunSet: { hour: 18, minute: 57 } },
  { sunRise: { hour: 5, minute: 31 }, sunSet: { hour: 19, minute: 9 } },
  { sunRise: { hour: 5, minute: 39 }, sunSet: { hour: 19, minute: 9 } },
  { sunRise: { hour: 5, minute: 53 }, sunSet: { hour: 18, minute: 52 } },
  { sunRise: { hour: 6, minute: 5 }, sunSet: { hour: 18, minute: 23 } },
  { sunRise: { hour: 6, minute: 19 }, sunSet: { hour: 17, minute: 46 } },
  { sunRise: { hour: 6, minute: 36 }, sunSet: { hour: 17, minute: 33 } },
  { sunRise: { hour: 6, minute: 56 }, sunSet: { hour: 17, minute: 39 } }
];

let currentSetTheme = "";

export const themeContext = createContext({
  mode: "",
  theme: "",
  cycleMode: () => { return; }
});

export function ThemeProvider(props: { children?: ReactNode }) {
  const [mode, setMode] = useState(
    localStorage.getItem("siteThemeMode") ||
    (isSystemThemeSupported ? "system" : "light")
  );
  const [theme, setTheme] = useState(
    localStorage.getItem("siteTheme") || "light-theme"
  );

  const updateTheme = useCallback(
    async (_mode?: string) => {
      let siteTheme = _mode || mode;

      if (siteTheme === "system") {
        siteTheme = isSystemThemeDarkMode ? "dark" : "light";
      } else if (siteTheme === "auto") {
        siteTheme = await isDayTime() ? "light" : "dark";
      }

      siteTheme = `${siteTheme}-theme`;

      localStorage.setItem("siteTheme", siteTheme);
      setTheme(siteTheme);
      applyTheme(siteTheme);
    },
    [mode]
  );

  function cycleMode() {
    const previousSiteMode = mode;
    let newSiteMode;

    if (previousSiteMode === "system") {
      newSiteMode = "auto";
    } else if (previousSiteMode === "auto") {
      newSiteMode = "light";
    } else if (previousSiteMode === "light") {
      newSiteMode = "dark";
    } else { // dark or not yet set.
      newSiteMode = isSystemThemeSupported ? "system" : "auto";
    }

    localStorage.setItem("siteThemeMode", newSiteMode);
    setMode(newSiteMode);

    if (newSiteMode === "auto") {
      localStorage.setItem("attemptToGetLocation", "true");
    }

    updateTheme(newSiteMode);
  }

  // Handles theme change based on sun set/rise.
  useEffect(() => {
    let timer: NodeJS.Timeout;

    async function triggerThemeChange() {
      updateTheme();
      const ms = await getMillisecondsUntilThemeChange();
      timer = setTimeout(() => { triggerThemeChange(); }, ms);
    }

    (async () => {
      const ms = await getMillisecondsUntilThemeChange();
      timer = setTimeout(() => { triggerThemeChange(); }, ms);
    })();

    return () => clearTimeout(timer);
  }, [mode, theme, updateTheme]);

  // Needed to update theme on 1st impression for auto theme to take effect.
  useEffect(() => { updateTheme(); }, [updateTheme]);

  return (
    <themeContext.Provider value={{ mode, theme, cycleMode }}>
      {props.children}
    </themeContext.Provider>
  );
}

function applyTheme(siteTheme: string) {
  if (siteTheme !== currentSetTheme && document.documentElement) {
    removeThemes();
    document.documentElement.classList.add(siteTheme);
    currentSetTheme = siteTheme;
  }
}

function removeThemes() {
  if (document.documentElement) {
    document.documentElement.classList.remove("light-theme", "dark-theme");
  }
}

function getMillisecondsUntilThemeChange() {
  return new Promise<number>(
    async (resolve) => {
      const millisecondsInAMinute = 60 * 1000;
      const now = new Date();
      const sunTimeFrame = sunTimeFramesByMonth[now.getMonth()];
      let delay: number;

      if (await isDayTime()) {
        const currentTimeInMinutes = (now.getHours() * 60) + now.getMinutes();
        const sunSetInMinutes = (
          (sunTimeFrame.sunSet.hour * 60) + sunTimeFrame.sunSet.minute
        );

        delay = (sunSetInMinutes - currentTimeInMinutes) *
          millisecondsInAMinute;
      } else {
        const minutesRemainingInDay = (
          ((23 - now.getHours()) * 60) + (60 - now.getMinutes())
        );
        const sunRiseInMinutes = (
          (sunTimeFrame.sunRise.hour * 60) + sunTimeFrame.sunRise.minute
        );

        delay = (minutesRemainingInDay + sunRiseInMinutes) *
          millisecondsInAMinute;
      }

      // Minimum delay is 1 minute else it may fire the timer in rapid succession.
      resolve(Math.max(delay, millisecondsInAMinute));
    }
  );
}

function isDayTime() {
  return new Promise<boolean>(
    async (resolve) => {
      const now = new Date();
      let itIsDayTime = true;
      let attemptToGetLocation = (
        localStorage.getItem("attemptToGetLocation") === "true"
      );

      if (attemptToGetLocation) {
        let coordinates: {
          latitude: number;
          longitude: number;
        } | undefined;

        try {
          coordinates = await getLocation();
        } catch (error) {
          attemptToGetLocation = false;
          localStorage.removeItem("attemptToGetLocation");
        }

        if (coordinates) {
          const times = getTimes(
            now,
            coordinates.latitude,
            coordinates.longitude
          );

          itIsDayTime = (
            now >= times.sunriseEnd &&
            now <= times.sunsetStart
          );
        }
      }

      if (!attemptToGetLocation) {
        const sunTimeFrame = sunTimeFramesByMonth[now.getMonth()];
        const currentTimeInMinutes = (now.getHours() * 60) + now.getMinutes();
        const sunRiseInMinutes = (
          (sunTimeFrame.sunRise.hour * 60) + sunTimeFrame.sunRise.minute
        );
        const sunSetInMinutes = (
          (sunTimeFrame.sunSet.hour * 60) + sunTimeFrame.sunSet.minute
        );
        itIsDayTime = (
          currentTimeInMinutes >= sunRiseInMinutes &&
          currentTimeInMinutes <= sunSetInMinutes
        );
      }

      resolve(itIsDayTime);
    }
  );
}
