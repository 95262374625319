import { stringify as qsStringify } from "querystring";

import { PaginatedList, ValidationError } from "dev-center-lib";
import {
  CREATED,
  NO_CONTENT,
  NOT_MODIFIED,
  OK
} from "dev-center-lib/build/src/GS/httpStatusCodes";

import config from "../config.json";
import { INavBarItem } from "../definitions/INavBarItem";
import { IRequestOptions } from "../definitions/IRequestOptions";

const INDEX_NOT_FOUND = -1;

export function extractBearerTokenFromQs() {
  const parsed = new URL(window.location.href);

  if (parsed.searchParams.get("bearerToken")) {
    window.localStorage.setItem(
      "bearerToken",
      parsed.searchParams.get("bearerToken")!
    );
  }
}

export function getBearerToken() {
  return window.localStorage.getItem("bearerToken");
}

// TODO(jc): Needs to be deleted once NavBar area is redone to use datastore.
export function deleteNavBarItem(id: number) {
  return request<void>(
    prepareUrl(`${config.apiServer}/nav-bar-items/${id}`),
    { method: "DELETE" }
  );
}

// TODO(jc): Needs to be deleted once NavBar area is redone to use datastore.
export function getNavBarItem(id: number) {
  return request<INavBarItem>(
    prepareUrl(`${config.apiServer}/nav-bar-items/${id}`)
  );
}

// TODO(jc): Needs to be deleted once NavBar area is redone to use datastore.
export function getNavBarItems() {
  return request<PaginatedList<INavBarItem>>(
    prepareUrl(`${config.apiServer}/nav-bar-items`)
  );
}

// TODO(jc): Needs to be deleted once NavBar area is redone to use datastore.
export function patchNavBarItem(id: number, navBarItem: INavBarItem) {
  const body = Object.assign({}, navBarItem);

  if (body.id) {
    delete body.id;
  }

  return request<INavBarItem>(
    prepareUrl(`${config.apiServer}/nav-bar-items/${id}`),
    {
      body,
      method: "PATCH"
    }
  );
}

// TODO(jc): Needs to be deleted once NavBar area is redone to use datastore.
export function postNavBarItem(navBarItem: INavBarItem) {
  return request<INavBarItem>(
    prepareUrl(`${config.apiServer}/nav-bar-items`),
    {
      body: navBarItem,
      method: "POST"
    }
  );
}

function convertToError(error: Error) {
  // TODO(jc): All internal errors should derive
  //   from a common error that requires a className.
  switch ((error as unknown as Record<string, string>).className) {
    case "ValidationError":
      return new ValidationError(error.name, error.message);
    default:
  }

  return new Error(error.message);

  // if (error.code === 401) {
  //   // authToken = { token: "" };
  //   // cookies.remove("authToken", { path: "/" });
  //   // _emit("change", authToken);
  //   return new UnauthorizedError();
  // }
}

export function prepareUrl(url: string, query: Record<string, any> = {}) {
  const _query = { ...query };

  for (const k in _query) {
    if (
      Object.hasOwnProperty.call(_query, k) &&
      (_query[k] === undefined || _query[k] === null || _query[k] === "")
    ) {
      delete _query[k];
    } else if (typeof _query[k] === "object") {
      _query[k] = JSON.stringify(_query[k]);
    }
  }

  const sep = url.indexOf("?") === INDEX_NOT_FOUND ? "?" : "&";
  const qs = qsStringify(_query);
  // google.com/abc.html?filter={t:f}&dsgsdfg=sdfgsd
  return url + (qs.length ? `${sep}${qs}` : "");
}

export function request<T>(url: string, options?: IRequestOptions<T>) {
  options = Object.assign(
    {
      body: null,
      method: "GET"
    },
    options
  );

  let validStatuses: number[];

  if (options!.method === "DELETE") {
    validStatuses = [OK, NO_CONTENT];
  } else if (options!.method === "GET") {
    validStatuses = [OK, NOT_MODIFIED];
  } else if (options!.method === "PATCH") {
    validStatuses = [OK, NO_CONTENT];
  } else if (options!.method === "POST") {
    validStatuses = [OK, CREATED];
  }

  return new Promise<T>(async (resolve, reject) => {
    try {
      // TODO(jc): We need to try to not depend on using `as unknown`.
      // let obj: string | Error;
      let obj: unknown;
      let response: Response;

      try {
        const headers: HeadersInit = {
          "Accept": "application/json",
          "Content-Type": "application/json"
        };
        const token = getBearerToken();

        if (token) {
          headers.authorization = `Bearer ${token}`;
        }

        response = await fetch(url, {
          body: options!.body ? JSON.stringify(options!.body) : undefined,
          headers,
          method: options!.method
        });
      } catch (error) {
        // There was an error connecting with the server.
        // Lets create a properly formatted json error.
        throw convertToError({
          code: undefined,
          message: "There was an unexpected error connecting to the server."
        } as any);
      }

      if (response.status !== NO_CONTENT) {
        try {
          obj = await response.json();

          if (typeof obj === "string") {
            obj = { message: obj };
          }
        } catch (error) {
          // The response body was not json.
          // Lets create a properly formatted json error.
          throw convertToError({
            code: response.status,
            message: "There was an unexpected response from the server."
          } as any);
        }

        if (validStatuses.indexOf(response.status) === INDEX_NOT_FOUND) {
          throw convertToError(obj as any);
        }
      }

      // obj will be undefined when the response.status
      //   is 204 (NO_CONTENT).
      resolve(obj as any);
    } catch (error) {
      reject(error);
    }
  });
}
