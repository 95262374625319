import React, {
  Fragment
} from "react";

export function MainSiteScreen() {
  return (
    <Fragment>
      <h1>Welcome</h1>
      <p>You are at the beginning.</p>
    </Fragment>
  );
}

export { MainSiteScreen as default };
