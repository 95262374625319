export function getLocation() {
  return new Promise<{ latitude: number, longitude: number }>(
    (resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
          reject
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    }
  );
}

export function toUiPhone(value: string): string {
  return value.replace(
    /^\+?1?\(?(\d{3})\)?[ -]?(\d{3})[ -]??(\d{4})$/,
    "($1) $2-$3"
  );
}

export function toDbPhone(value: string): string {
  return value.replace(
    /^\+?1?\(?(\d{3})\)?[ -]?(\d{3})[ -]??(\d{4})$/,
    "+1$1$2$3"
  );
}

export function toLocalTime(utcTime: string | null): string {
  if (!utcTime) { return utcTime || ""; }
  const d = new Date();
  const timeParts = utcTime.split(":");
  d.setUTCHours(parseInt(timeParts[0], 10));
  return `0${d.getHours()}`.substr(-2) + `:${timeParts[1]}`;
}

export function toUtcTime(localTime: string | null): string {
  if (!localTime) { return localTime || ""; }
  const d = new Date();
  const timeParts = localTime.split(":");
  d.setHours(parseInt(timeParts[0], 10));
  return `0${d.getUTCHours()}`.substr(-2) + `:${timeParts[1]}`;
}
