import {
  IconProp
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, Props } from "@fortawesome/react-fontawesome";
import React from "react";

/* HACK(jc): Ignore error required for custom fonts to work */

// NOTE(jc): To add new font also change: ./index.ts, ../common/fonts.ts
export type ExtendedIconProps = (
  IconProp |
  ["fa-extended", "700-credit"] |
  ["fa-extended", "beacon"] |
  ["fa-extended", "beginning-with"] |
  ["fa-extended", "between"] |
  ["fa-extended", "build"] |
  ["fa-extended", "contains"] |
  ["fa-extended", "does-not-contain"] |
  ["fa-extended", "ellipsis-square-h"] |
  ["fa-extended", "ending-with"] |
  ["fa-extended", "menu"] |
  ["fa-extended", "staff"]
);

export function FontAwesomeExtendedIcon(props: IExtendedProps): JSX.Element {
  // @ts-ignore
  return <FontAwesomeIcon {...props} />;
}

// @ts-ignore
export interface IExtendedProps extends Props {
  icon: ExtendedIconProps;
  mask?: ExtendedIconProps;
}
