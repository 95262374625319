// NOTE(jc): To add new font also change: ./index.ts, ./FontAwesomeExtendedIcon.tsx, ../common/fonts.ts

import {
  IconDefinition
} from "@fortawesome/fontawesome-common-types";

export const prefix = "fa-extended";
export const iconName = "700-credit";
export const width = 640;
export const height = 512;
export const ligatures: string[] = [];
// This is being used only for SVG so unicode is not needed.
export const unicode = "";
export const svgPathData = "m72.629 192.83a256 256 0 0 0 -8.629 64.66 256 256 0 0 0 219.15 253.01c-37.015-132.22-137.36-274.09-210.52-317.67zm46.203-93.198c-3.5851 4.7033-7.0061 9.5295-10.257 14.47 165.57 83.136 274.05 220.26 369.62 344.13 12.651-9.8641 24.348-20.895 34.934-32.949-108.07-117.94-229.51-232.36-394.3-325.65zm201.17-98.142a256 256 0 0 0 -164.27 59.98c177.17 42.858 264.62 93.679 416.29 239.42a256 256 0 0 0 3.9763 -43.404 256 256 0 0 0 -256 -256z";

export const definition = {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
} as unknown as IconDefinition;

export const fa700Credit = definition;
