import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCircle,
  faQuestionCircle
} from "@fortawesome/free-regular-svg-icons";

import {
  faArrowsAltV, // Used by Table
  faBars, // Used for Menu in nav
  faBuilding, // Used for Clients in nav
  faBullseye, // Used for Targeting in nav
  faChartPie, // Used for Analytics in nav
  faCheck, // Used by Table
  faChevronLeft, // Used by Table Pagination
  faChevronRight, // Used by Table Pagination
  faClock, // Used by Theme button
  faEdit,
  faEnvelope, // Used for Messaging in nav
  faEquals, // Used TableSearchOperator dropdown
  faEye, // Used by password strength field
  faEyeSlash, // Used by password strength field
  faFile,
  faFileAlt,
  faGift, // Used for Rewards in nav
  faGreaterThan, // Used TableSearchOperator dropdown
  faGreaterThanEqual, // Used TableSearchOperator dropdown
  faHome,
  faImage,
  faLessThan, // Used TableSearchOperator dropdown
  faLessThanEqual, // Used TableSearchOperator dropdown
  faMapMarkerAlt,
  faMicrochip, // Used by Theme button
  faMinus,
  faMobileAlt,
  faMoon, // Used by Theme button
  faNotEqual, // Used TableSearchOperator dropdown
  faPencilAlt,
  faPlus,
  faQuestion,
  faRocket, // Used for Launch in nav
  faSave,
  faSearch,
  faShapes, // Used for Jobs in nav
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faSun, // Used by Theme button
  faTimes, // Used by Table,
  faTrashAlt, // Used by HomeScreenScreen, Table
  faUsers,
  faVideo
} from "@fortawesome/free-solid-svg-icons";

// NOTE(jc): To add new font also change:
//   ../font-awesome-extended/index.ts,
//   ../font-awesome-extended/FontAwesomeExtendedIcon.tsx
import {
  fa700Credit, // Used for 700 Credit in nav
  faBeacon, // Used for Beacons in nav
  faBeginsWith, // Used TableSearchOperator dropdown
  faBetween, // Used TableSearchOperator dropdown
  // faBuild,
  faContains, // Used TableSearchOperator dropdown
  faDoesNotContain, // Used TableSearchOperator dropdown
  faEllipsisSquareH,
  faEndsWith, // Used TableSearchOperator dropdown
  faMenu,
  faStaff // Used for Staff in nav
} from "../font-awesome-extended";

// free-regular-svg-icons
library.add(
  faCircle,
  faQuestionCircle
);

// free-solid-svg-icons
library.add(
  faArrowsAltV,
  faBars,
  faBuilding,
  faBullseye,
  faChartPie,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faClock,
  faEdit,
  faEnvelope,
  faEquals,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faGift,
  faGreaterThan,
  faGreaterThanEqual,
  faHome,
  faImage,
  faLessThan,
  faLessThanEqual,
  faMapMarkerAlt,
  faMicrochip,
  faMinus,
  faMobileAlt,
  faMoon,
  faNotEqual,
  faPencilAlt,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRocket,
  faSave,
  faSearch,
  faShapes,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faSun,
  faTimes,
  faTrashAlt,
  faUsers,
  faVideo
);

// font-awesome-extended
library.add(
  fa700Credit,
  faBeacon,
  faBeginsWith,
  faBetween,
  // faBuild,
  faContains,
  faDoesNotContain,
  faEllipsisSquareH,
  faEndsWith,
  faMenu,
  faStaff
);
