import {
  IPushNotificationLogDatastore,
  PushNotificationLogDto,
  pushNotificationLogQueryType,
  pushNotificationLogQueryExpand
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class PushNotificationLogDatastoreRestClient
  implements IPushNotificationLogDatastore {

  public addAsync(userDto: PushNotificationLogDto) {
    return new Promise<PushNotificationLogDto>(
      async (resolve, reject) => {
        try {
          const newPushNotificationLogDto =
            await request<PushNotificationLogDto>(
              prepareUrl(
                `${config.apiServer}/testing/push-notification-logs`
              ),
              {
                body: userDto,
                method: "POST"
              }
            );

          resolve(new PushNotificationLogDto(
            newPushNotificationLogDto
          ));
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public findAsync(query: pushNotificationLogQueryType) {
    return new Promise<{
      dtos: PushNotificationLogDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: PushNotificationLogDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              `${config.apiServer}/testing/push-notification-logs`,
              query
            )
          );

          response.dtos = response.dtos.map(
            (dto) => (new PushNotificationLogDto(dto))
          );
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number, expand?: pushNotificationLogQueryExpand) {
    return new Promise<PushNotificationLogDto>(
      async (resolve, reject) => {
        try {
          const userDto = await request<PushNotificationLogDto>(
            prepareUrl(
              config.apiServer +
              `testing/push-notification-logs/${id}`,
              { expand }
            )
          );
          resolve(new PushNotificationLogDto(userDto));
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public removeAsync(id: number) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await request(
          prepareUrl(
            config.apiServer +
            `testing/push-notification-logs/${id}`
          ),
          { method: "DELETE" }
        );

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAsync(userDto: PushNotificationLogDto) {
    return new Promise<PushNotificationLogDto>(
      async (resolve, reject) => {
        try {
          const updatedPushNotificationLogDto =
            await request<PushNotificationLogDto>(
              prepareUrl(
                config.apiServer +
                `testing/push-notification-logs/${userDto.id}`
              ),
              {
                body: userDto,
                method: "PATCH"
              }
            );

          resolve(new PushNotificationLogDto(
            updatedPushNotificationLogDto
          ));
        } catch (error) {
          reject(error);
        }
      }
    );
  }
}
