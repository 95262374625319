// NOTE(jc): To add new font also change: ./index.ts, ./FontAwesomeExtendedIcon.tsx, ../common/fonts.ts

import {
  IconDefinition
} from "@fortawesome/fontawesome-common-types";

export const prefix = "fa-extended";
export const iconName = "staff";
export const width = 640;
export const height = 512;
export const ligatures: string[] = [];
// This is being used only for SVG so unicode is not needed.
export const unicode = "";
export const svgPathData = "m320 32c-61.862 0-112 50.138-112 112 0 61.862 50.138 112 112 112 61.863 0 112-50.138 112-112 0-61.862-50.137-112-112-112zm-224 64c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm448 0c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm-480 160c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.899-87.3 75.199-109.4-11.6-11.5-27.5-18.6-45.1-18.6zm448 0c-17.6 0-33.5 7.0996-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-234 28 28 49-28 119-41.824-167.47c-62.388 2.975-112.18 53.987-112.18 117.07v36.4c0 23.188 18.812 42 42 42h308c23.188 0 42-18.812 42-42v-36.4c0-63.088-49.788-114.1-112.18-117.07l-41.824 167.47-28-119 28-49z";

export const definition = {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
} as unknown as IconDefinition;

export const faStaff = definition;
