import loadable from "@loadable/component";
import React from "react";

import { LoadingMessage } from "./LoadingMessage";

// TODO(jc): We need to add a way to catch errors from network issues when
//   loading components. Look into Error Boundaries.
//   https://reactjs.org/docs/error-boundaries.html
export const LoadableAnalyticsScreen = loadable(
  () => import("../screens/AnalyticsScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableClientEditScreen = loadable(
  () => import("../screens/ClientEditScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableClientsScreen = loadable(
  () => import("../screens/ClientsScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableHomeScreenScreen = loadable(
  () => import("../screens/HomeScreenScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableLoyaltyRewardsTasksEditScreen = loadable(
  () => import("../screens/LoyaltyRewardsTasksEditScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableLoyaltyRewardsTasksScreen = loadable(
  () => import("../screens/LoyaltyRewardsTasksScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableNoMatchScreen = loadable(
  () => import("../screens/NoMatchScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableStrapiPageScreen = loadable(
  () => import("../screens/StrapiPageScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableUserEditScreen = loadable(
  () => import("../screens/UserEditScreen"),
  { fallback: <LoadingMessage /> }
);

export const LoadableUsersScreen = loadable(
  () => import("../screens/UsersScreen"),
  { fallback: <LoadingMessage /> }
);
