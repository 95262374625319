import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import "./themes/dark-theme.scss";
import "./themes/light-theme.scss";

import styles from "./App.module.scss";

import cn from "classnames";
import React, { useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { Container } from "reactstrap";

import { extractBearerTokenFromQs } from "./common/apiClient";
import "./common/fonts";
import { HelpProvider } from "./components/Help";
import { SiteHeader } from "./components/SiteHeader";
import { SiteNavigation } from "./components/SiteNavigation";
import { ThemeProvider } from "./components/Theme";
import { MainSiteScreen } from "./screens/MainSiteScreen";

import {
  LoadableAnalyticsScreen,
  LoadableClientEditScreen,
  LoadableClientsScreen,
  LoadableHomeScreenScreen,
  LoadableLoyaltyRewardsTasksEditScreen,
  LoadableLoyaltyRewardsTasksScreen,
  LoadableNoMatchScreen,
  LoadableStrapiPageScreen,
  LoadableUserEditScreen,
  LoadableUsersScreen
} from "./components/LoadableComponents";

import {
  AnalyticsEventDatastoreRestClient
} from "./datastores/AnalyticsEventDatastoreRestClient";
import {
  AnalyticsNewCustomerProfileDatastoreRestClient
} from "./datastores/AnalyticsNewCustomerProfileDatastoreRestClient";
import {
  AnalyticsNewDownloadDatastoreRestClient
} from "./datastores/AnalyticsNewDownloadDatastoreRestClient";
import {
  AnalyticsPushNotificationDatastoreRestClient
} from "./datastores/AnalyticsPushNotificationDatastoreRestClient";
import { AppDatastoreRestClient } from "./datastores/AppDatastoreRestClient";
import {
  AppVersionDatastoreRestClient
} from "./datastores/AppVersionDatastoreRestClient";
import {
  ClientDatastoreRestClient
} from "./datastores/ClientDatastoreRestClient";
import {
  CustomerProfileDatastoreRestClient
} from "./datastores/CustomerProfileDatastoreRestClient";
import {
  LocationDatastoreRestClient
} from "./datastores/LocationDatastoreRestClient";
import {
  LoyaltyRewardTaskDatastoreRestClient
} from "./datastores/LoyaltyRewardTaskDatastoreRestClient";
import {
  LoyaltyRewardsLedgerEntryDatastoreRestClient
} from "./datastores/LoyaltyRewardsLedgerEntryDatastoreRestClient";
import {
  PushNotificationLogDatastoreRestClient
} from "./datastores/PushNotificationLogDatastoreRestClient";
import { UserDatastoreRestClient } from "./datastores/UserDatastoreRestClient";
import { dependencies } from "./dependencies";

dependencies.analyticsEventDatastore =
  new AnalyticsEventDatastoreRestClient();
  dependencies.analyticsNewCustomerProfileDatastore =
  new AnalyticsNewCustomerProfileDatastoreRestClient();
dependencies.analyticsNewDownloadDatastore =
  new AnalyticsNewDownloadDatastoreRestClient();
dependencies.analyticsPushNotificationDatastore = 
  new AnalyticsPushNotificationDatastoreRestClient();
dependencies.appDatastore = new AppDatastoreRestClient();
dependencies.appVersionDatastore = new AppVersionDatastoreRestClient();
dependencies.locationDatastore = new LocationDatastoreRestClient();
dependencies.clientDatastore = new ClientDatastoreRestClient();
dependencies.customerProfileDatastore =
  new CustomerProfileDatastoreRestClient();
dependencies.loyaltyRewardsTasksDatastore =
  new LoyaltyRewardTaskDatastoreRestClient();
dependencies.loyaltyRewardsLedgerEntryDatastore =
  new LoyaltyRewardsLedgerEntryDatastoreRestClient();
dependencies.pushNotificationLogDatastore =
  new PushNotificationLogDatastoreRestClient();
dependencies.userDatastore = new UserDatastoreRestClient();

extractBearerTokenFromQs();

export function App() {
  const mainScreen = useCallback(() => {
    return (
      <ScreenWrap isMain={true}>
        <MainSiteScreen />
      </ScreenWrap>
    );
  }, []);

  const subScreen = useCallback(() => {
    return (
      <ScreenWrap isMain={false}>
        <SubScreens />
      </ScreenWrap>
    );
  }, []);

  return (
    <Router>

      <Switch>
        <Route
          path="/analytics-fragment"
          render={(props) => (
            <HelpProvider>
              <LoadableAnalyticsScreen {...props} displayAsFragment={true} />
            </HelpProvider>
          )}
          exact={true}
        />

        <Route
          path={[
            "/pages-fragment/:id(\\d*)",
            "/launch-fragment",
            "/launch-fragment/:id(\\d*)"
          ]}
          render={(props) => (
            <HelpProvider>
              <LoadableStrapiPageScreen {...props} displayAsFragment={true} />
            </HelpProvider>
          )}
          exact={true}
        />

        <Route render={() => (
          <ThemeProvider>
            <HelpProvider>
              <SiteHeader />

              <Switch>
                <Route path="/" component={mainScreen} exact={true} />

                <Route component={subScreen} />
              </Switch>
            </HelpProvider>
          </ThemeProvider>
        )} />
      </Switch>

    </Router>
  );
}

function ScreenWrap(props: { isMain: boolean, children: JSX.Element }) {
  return (
    <div className="d-flex">
      <Container
        className={cn(
          "d-lg-flex",
          styles.containerOuterWrap,
          (props.isMain ? styles.mainScreen : styles.subScreen)
        )}
      >
        <div className={`d-lg-flex ${styles.navigationWrapper}`}>
          <Route component={SiteNavigation} />
        </div>

        <div style={{ width: "100%" }}>
          <Container
            tag="main"
            id="mainContent"
            tabIndex={-1}
          >
            <div className={styles.containerInnerWrap}>
              {props.children}
            </div>
          </Container>
        </div>
      </Container>
    </div>
  );
}

function SubScreens() {
  return (
    <Switch>
      <Route
        path="/analytics-screen"
        component={LoadableAnalyticsScreen}
        exact={true}
      />

      <Route
        path="/home-screen"
        component={LoadableHomeScreenScreen}
        exact={true}
      />

      {/* <Route
          path="/nav-bar"
          component={NavBarScreen}
          exact={true}
        /> */}

      <Route
        path={["/pages/:id(\\d*)", "/launch", "/launch/:id(\\d*)"]}
        component={LoadableStrapiPageScreen}
        exact={true}
      />

      <Route
        path="/users"
        component={LoadableUsersScreen}
        exact={true}
      />

      <Route
        path="/users/new"
        component={LoadableUserEditScreen}
        exact={true}
      />

      <Route
        path="/users/:id(\d+)"
        component={LoadableUserEditScreen}
        exact={true}
      />

      <Route
        path="/clients"
        component={LoadableClientsScreen}
        exact={true}
      />

      <Route
        path="/clients/new"
        component={LoadableClientEditScreen}
        exact={true}
      />

      <Route
        path="/clients/:id(\d+)"
        component={LoadableClientEditScreen}
        exact={true}
      />

      <Route
        path="/rewards"
        component={LoadableLoyaltyRewardsTasksScreen}
        exact={true}
      />

      <Route
        path="/rewards/new"
        component={LoadableLoyaltyRewardsTasksEditScreen}
        exact={true}
      />

      <Route
        path="/rewards/:id(\d+)"
        component={LoadableLoyaltyRewardsTasksEditScreen}
        exact={true}
      />

      <Route component={LoadableNoMatchScreen} />

    </Switch>
  );
}
