import {
  AnalyticsEventDto,
  IAnalyticsEventDatastore,
  analyticsEventQueryType
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class AnalyticsEventDatastoreRestClient
  implements IAnalyticsEventDatastore {

  // TODO(jc): This will not be implemented but the interface needs it.
  //   Need to handle this better.
  public addAsync(analyticsEventDto: AnalyticsEventDto) {
    return new Promise<AnalyticsEventDto>(async (resolve, reject) => {
      try {
        const newAnalyticsEventDto = await request<AnalyticsEventDto>(
          prepareUrl(
            `${config.apiServer}/analytics/analytics-events`
          ),
          {
            body: analyticsEventDto,
            method: "POST"
          }
        );

        resolve(new AnalyticsEventDto(newAnalyticsEventDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public findAsync(query: analyticsEventQueryType) {
    return new Promise<{
      dtos: AnalyticsEventDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: AnalyticsEventDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              `${config.apiServer}/analytics/analytics-events`,
              query
            )
          );

          response.dtos = response.dtos.map(
            (dto) => (new AnalyticsEventDto(dto))
          );
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  // TODO(jc): This will not be implemented but the interface needs it.
  //   Need to handle this better.
  public getAsync(id: number) {
    return new Promise<AnalyticsEventDto>(async (resolve, reject) => {
      try {
        const analyticsEventDto = await request<AnalyticsEventDto>(
          prepareUrl(
            `${config.apiServer}/analytics/analytics-events/${id}`
          )
        );
        resolve(new AnalyticsEventDto(analyticsEventDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public removeAsync(id: number) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await request(
          prepareUrl(
            `${config.apiServer}/administrators/analytics/analytics-events/${id}`
          ),
          { method: "DELETE" }
        );

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  // TODO(jc): This will not be implemented but the interface needs it.
  //   Need to handle this better.
  public updateAsync(analyticsEventDto: AnalyticsEventDto) {
    return new Promise<AnalyticsEventDto>(async (resolve, reject) => {
      try {
        const updatedAnalyticsEventDto = await request<AnalyticsEventDto>(
          prepareUrl(
            `${config.apiServer}/` +
            `analytics-new-downloads/${analyticsEventDto.id}`
          ),
          {
            body: analyticsEventDto,
            method: "PATCH"
          }
        );

        resolve(new AnalyticsEventDto(updatedAnalyticsEventDto));
      } catch (error) {
        reject(error);
      }
    });
  }
}
