import styles from "./SiteNavigation.module.scss";

import cn from "classnames";
import React from "react";
import {
  useEffect,
  useState
} from "react";
import posed from "react-pose";
import {
  matchPath,
  NavLink as RouterNavLink,
  Route,
  RouteComponentProps
} from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";

import {
  ExtendedIconProps,
  FontAwesomeExtendedIcon
} from "../font-awesome-extended/FontAwesomeExtendedIcon";
import { HelpPopover } from "./Help";

const menuItemsInfo: IMenuItemInfo[] = [
  {
    url: "/jobs",
    icon: "shapes",
    title: "Jobs",
    enabled: false
  },
  {
    id: "siteNavigationUsers",
    url: "/users",
    helpText: "Administer the users in the system.",
    icon: ["fas", "users"],
    title: "Users",
    enabled: true
  },
  {
    id: "siteNavigationClients",
    url: "/clients",
    helpText: "Administer the clients in the system.",
    icon: "building",
    title: "Clients",
    enabled: true
  },
  {
    url: "/apps",
    icon: "mobile-alt",
    title: "Apps",
    enabled: false
  },
  {
    url: "/analytics-screen",
    icon: "chart-pie",
    title: "Analytics",
    enabled: true
  },
  {
    id: "siteNavigationMessaging",
    url: "/messaging",
    helpText: "Create and send push notifications now or schedule.",
    icon: "envelope",
    title: "Messaging",
    enabled: false
  },
  {
    id: "siteNavigationTargeting",
    url: "/targeting",
    helpText: "Target app users and send them unique push notifications.",
    icon: "bullseye",
    title: "Targeting",
    enabled: false
  },
  {
    id: "siteNavigationFencing",
    url: "/fencing",
    helpText: "Create zone notifications that trigger when a user enters.",
    icon: "map-marker-alt",
    title: "Fencing",
    enabled: false
  },
  {
    id: "siteNavigationBeacons",
    url: "/beacons",
    helpText: "Setup your beacons and create content.",
    icon: ["fa-extended", "beacon"],
    title: "Beacons",
    enabled: false
  },
  {
    id: "siteNavigationRewards",
    url: "/rewards",
    helpText: "Administer the loyalty rewards and tasks for a location.",
    icon: "gift",
    title: "Rewards",
    enabled: true
  },
  {
    url: "/700-credit",
    icon: ["fa-extended", "700-credit"],
    title: "700 Credit",
    enabled: false
  },
  {
    id: "siteNavigationStaff",
    url: "/staff",
    helpText: "Add/Remove staff members to your app's staff page.",
    icon: ["fa-extended", "staff"],
    title: "Staff",
    enabled: false
  },
  {
    url: "/home-screen",
    icon: "home",
    title: "Edit Home",
    enabled: true
  },
  {
    url: "/menu",
    icon: "bars",
    title: "Menu",
    enabled: false
  },
  {
    url: "/nav-bar",
    icon: ["fa-extended", "ellipsis-square-h"],
    title: "Nav Bar",
    enabled: false
  },
  {
    url: "/custom-forms",
    icon: "file-alt",
    title: "Forms",
    enabled: false
  },
  {
    url: "/launch",
    icon: "rocket",
    title: "Launch",
    enabled: true
  }
];

const MenuItemsContainer = posed.nav({
  open: {
    height: "auto",
    transition: {
      type: "spring",
      stiffness: 1000,
      damping: 30
    }
  },
  peek: {
    height: "0.5rem",
    transition: {
      type: "spring",
      stiffness: 1000,
      damping: 10
    }
  },
  closed: { height: 0, delay: 0 }
});

const MenuItems = posed.ul({
  open: {
    delayChildren: 10,
    staggerChildren: 30
  }
});

const MenuItem = posed.li({
  open: { y: 0, opacity: 1 },
  closed: { y: 150, opacity: 0 }
});

function renderScreenReaderCurrent() {
  return <span className="sr-only">(current)</span>;
}

export function SiteNavigation(props: RouteComponentProps<any>) {
  const menuPeekStopOnClick = 3;
  const [menuMode, setMenuMode] = useState("closed");
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  function getMenuOpenCount() {
    return parseInt(
      localStorage.getItem("siteMenuOpenCount") || "0"
      , 10
    );
  }

  function menuButtonClicked() {
    const toMenuMode = menuMode === "open" ? "closed" : "open";
    setMenuMode(toMenuMode);

    if (toMenuMode === "open") {
      const menuOpenCount = getMenuOpenCount();

      localStorage.setItem(
        "siteMenuOpenCount",
        Math.min(menuOpenCount + 1, 1000000).toString(10)
      );
    }
  }

  function updateDimensions() {
    if (window.innerWidth >= 992) {
      if (!isLargeScreen) {
        setIsLargeScreen(true);
      }
    } else {
      if (isLargeScreen) {
        setIsLargeScreen(false);
      }
    }
  }

  // Handle periodic peeking of menu
  useEffect(() => {
    let timer: NodeJS.Timeout;
    const menuOpenCount = getMenuOpenCount();
    const menuPeekTimeout = menuOpenCount === 0 ? 5000 : 10000;

    if (menuOpenCount < menuPeekStopOnClick) {
      timer = setTimeout(() => {
        if (menuMode !== "open") {
          setMenuMode("peek");
        }
      }, menuPeekTimeout);
    }

    return () => {
      clearTimeout(timer);
    };
  });

  // Track screen size change
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  });

  const currentAreaArray = menuItemsInfo
    .filter((menuItemInfo) => (
      !!matchPath(props.location.pathname, menuItemInfo.url)
    ))
    .map((menuItemInfo) => (
      <CurrentArea menuItemInfo={menuItemInfo} />
    ));

  return (
    <Card className={`d-print-none ${styles.navigationDrawer}`}>
      <CardHeader
        className="text-center position-relative p-0 shadow-up d-lg-none"
      >
        <button
          id="siteNavigationMenuButton"
          type="button"
          className={`
            btn btn-secondary position-absolute p-2 shadow-up
            ${styles.siteNavigationMenuButton}
          `}
          onClick={menuButtonClicked}
        >
          <FontAwesomeExtendedIcon
            icon={["fa-extended", "menu"]}
            size="lg"
            fixedWidth={true}
          />
          <span className="sr-only">Toggle Navigation Drawer</span>
        </button>
        <HelpPopover
          target="siteNavigationMenuButton"
          title="Navigation Drawer"
          text={`
            Toggles the navigation drawer. Access the navigation drawer to go
            to different areas.
          `}
          className={`
            d-lg-none ${styles.navigationDrawerHelpPopover}
          `}
        />
        {currentAreaArray.length > 0 ? currentAreaArray[0] : <CurrentArea />}
      </CardHeader>
      <CardBody className="p-0">
        <MenuItemsContainer
          pose={menuMode}
          className="navbar p-0"
          style={{ overflow: "hidden" }}
          onPoseComplete={(pose) => {
            if (pose === "peek") {
              setMenuMode("closed");
            }
          }}
        >
          <MenuItems
            className={`
                navbar-nav
                d-flex
                flex-row
                flex-wrap
                align-items-center
                justify-content-center
                list-unstyled
                mx-auto
                mb-0
                pt-3
                pb-2
              `}
          >

            {/* TODO(jc): Disabled menu items are still
                  clickable using the keyboard */}
            {/* TODO(jc): Menu items are still clickable using
                  the keyboard when the menu is closed */}
            {menuItemsInfo.map((menuItemInfo) => (
              <MenuItem
                key={menuItemInfo.url}
                className={cn(
                  "navbar-item",
                  {
                    [styles.activeNavbarItem]: !!matchPath(
                      props.location.pathname,
                      menuItemInfo.url
                    )
                  }
                )}
              >
                <RouterNavLink
                  id={menuItemInfo.id}
                  to={menuItemInfo.url}
                  onClick={() => { setMenuMode("closed"); }}
                  className={cn(
                    "nav-link",
                    "d-flex",
                    "flex-column",
                    "flex-lg-row",
                    "align-items-center",
                    "justify-content-center",
                    "justify-content-lg-start",
                    {
                      "disabled": !menuItemInfo.enabled
                    }
                  )}
                >
                  <FontAwesomeExtendedIcon
                    icon={menuItemInfo.icon as ExtendedIconProps}
                    size="lg"
                    fixedWidth={true}
                  />
                  {menuItemInfo.title}
                  <Route
                    path={menuItemInfo.url}
                    render={renderScreenReaderCurrent}
                  />
                </RouterNavLink>
                {
                  (
                    menuMode === "open" ||
                    isLargeScreen
                  ) &&
                    menuItemInfo.id &&
                    menuItemInfo.helpText ?
                    <HelpPopover
                      target={menuItemInfo.id}
                      title={menuItemInfo.title}
                      text={menuItemInfo.helpText}
                      className={styles.navigationDrawerHelpPopover}
                    /> :
                    null
                }
              </MenuItem>
            ))}

          </MenuItems>
        </MenuItemsContainer>
      </CardBody>
    </Card>
  );
}

function CurrentArea(props: { menuItemInfo?: IMenuItemInfo }) {
  if (props.menuItemInfo) {
    return (
      <div
        id="siteNavigationCurrentArea"
        className={`
            float-left
            d-flex
            align-items-center
            justify-content-start
            px-3
            py-1
          `}
      >
        <FontAwesomeExtendedIcon
          icon={props.menuItemInfo.icon as ExtendedIconProps}
          size="xs"
          fixedWidth={true}
          className="mr-1"
        />{" "}
        <small>{props.menuItemInfo.title}</small>
        <HelpPopover
          target="siteNavigationCurrentArea"
          title="Current Area"
          text="Indicates the current area you are viewing."
          className={`d-lg-none ${styles.navigationDrawerHelpPopover}`}
        />
      </div>
    );
  }

  return (
    <div
      key="siteNavigationCurrentArea"
      id="siteNavigationCurrentArea"
      className={`
          float-left
          d-flex
          align-items-center
          justify-content-start
          px-3
          py-1
        `}
    >&nbsp;</div>
  );
}

interface IMenuItemInfo {
  id?: string;
  url: string;
  helpText?: string;
  icon: ExtendedIconProps;
  title: string;
  enabled: boolean;
}
