// NOTE(jc): To add new font also change: ./index.ts, ./FontAwesomeExtendedIcon.tsx, ../common/fonts.ts

import {
  IconDefinition
} from "@fortawesome/fontawesome-common-types";

export const prefix = "fa-extended";
export const iconName = "beacon";
export const width = 640;
export const height = 512;
export const ligatures: string[] = [];
// This is being used only for SVG so unicode is not needed.
export const unicode = "";
export const svgPathData = "m158.42 0.0011012c-2.7016 0.039622-5.3833 1.1388-7.3757 3.2929-106.01 114.61-105.97 292.86 0 407.42 3.9848 4.3083 10.731 4.3928 14.865 0.22683l21.976-22.151c3.946-3.9719 4.029-10.362 0.24472-14.489-86.476-94.394-86.495-240.19 0-334.6 3.7843-4.1271 3.6948-10.519-0.24472-14.491l-21.976-22.149c-2.0668-2.0797-4.7876-3.0997-7.4891-3.0601zm323.15 0c-2.7016-0.038819-5.4223 0.9831-7.4891 3.0661l-21.976 22.149c-3.946 3.9719-4.029 10.362-0.24473 14.489 86.476 94.394 86.495 240.19 0 334.6-3.7843 4.1271-3.6948 10.519 0.24473 14.491l21.976 22.149c4.1336 4.1595 10.88 4.0755 14.865-0.23278 106.01-114.61 105.97-292.86 0-407.42-1.9924-2.1541-4.6742-3.2521-7.3757-3.2909zm-73.464 72.452c-2.7282-0.06388-5.4872 0.95946-7.5766 3.078l-21.988 22.278c-3.8296 3.8813-4.0881 10.13-0.51732 14.264 46.945 54.306 46.841 135.68 0 189.85-3.5708 4.1336-3.3187 10.383 0.51732 14.264l21.988 22.28c4.1789 4.2436 11.037 4.093 14.976-0.36411 65.937-74.56 65.866-187.73 0-262.21-1.973-2.2318-4.6713-3.3822-7.3996-3.4461zm-176.22 4e-3c-2.729 0.06388-5.4278 1.2156-7.3976 3.4441-65.937 74.56-65.866 187.73 0 262.21 3.946 4.4635 10.797 4.6052 14.976 0.36809l21.988-22.278c3.8296-3.8814 4.0881-10.13 0.51731-14.264-46.945-54.306-46.841-135.68 0-189.85 3.5708-4.1336 3.3188-10.383-0.51731-14.264l-21.988-22.28c-2.0894-2.1218-4.8496-3.1439-7.5786-3.08zm88.15 93.146c-22.867 0-41.401 18.534-41.401 41.401s18.534 41.401 41.401 41.401c22.867 0 41.401-18.534 41.401-41.401s-18.534-41.401-41.401-41.401zm-17.207 99.569c-6.6231 0-12.083 7.7363-12.414 17.589l-7.0434 209.8c-0.35466 10.574 5.3051 19.437 12.414 19.437h48.442c7.1083 0 12.768-8.8628 12.414-19.437l-7.0434-209.8c-0.33097-9.8523-5.7904-17.589-12.414-17.589z";

export const definition = {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
} as unknown as IconDefinition;

export const faBeacon = definition;
