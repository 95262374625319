import styles from "./SiteHeader.module.scss";

import React, { Fragment } from "react";
import { useContext } from "react";
import {
  NavLink as RouterNavLink
} from "react-router-dom";
import {
  Container,
  Navbar,
  NavbarBrand
} from "reactstrap";

import {
  FontAwesomeExtendedIcon
} from "../font-awesome-extended/FontAwesomeExtendedIcon";
import { HelpPopover, HelpToggle } from "./Help";
import { isSystemThemeSupported, themeContext } from "./Theme";

import { DealerAppLogo } from "./DealerAppLogo";

export function SiteHeader() {
  const theme = useContext(themeContext);
  let icon: "clock" | "microchip" | "moon" | "sun";
  let title: string;

  if (theme.mode === "light") {
    icon = "sun";
    title = "Light Theme";
  } else if (theme.mode === "dark") {
    icon = "moon";
    title = "Dark Theme";
    // If !isSystemThemeSupported default to auto
  } else if (theme.mode === "auto" || !isSystemThemeSupported) {
    icon = "clock";
    title = "Auto Theme";
    // If isSystemThemeSupported default to system
  } else {
    icon = "microchip";
    title = "System Theme";
  }

  return (
    <Fragment>
      <div role="banner">
        <a href="#mainContent" className="sr-only sr-only-focusable">
          Skip to main content
        </a>

        <Container>
          <Navbar tag="div" className="px-0">
            {/* TODO(jc): I think that the title may cause the words
            to be repeated for the visually impaired since their is
            also an sr-only text. */}
            <RouterNavLink to="/" title="DealerApp Vantage">
              <NavbarBrand tag="span">
                <DealerAppLogo />
                <span className="sr-only">DealerApp Vantage</span>
              </NavbarBrand>
            </RouterNavLink>
            <button
              id="siteHeaderThemeButton"
              title={title}
              type="button"
              className={`
                btn btn-link nav-link ml-auto p-0 mr-0 mr-sm-3
                d-print-none ${styles.themButton}
              `}
              onClick={theme.cycleMode}
            >
              <span className="fa-layers fa-fw mr-2">
                <FontAwesomeExtendedIcon
                  icon={icon}
                  size="lg"
                  transform="shrink-8"
                />
                <FontAwesomeExtendedIcon icon={["far", "circle"]} size="lg" />
              </span>
              <span className="d-none d-sm-inline" aria-hidden={true}>
                {title}
              </span>
            </button>
            <HelpPopover
              target="siteHeaderThemeButton"
              title="Toggle Site Theme"
              text={`
                Toggles between Light, Dark, Auto, and System Theme.
                Auto Theme automatically changes between Light
                (during the day) and Dark (during the night).
                ${
                isSystemThemeSupported ?
                  ` System Theme will select Dark or Light theme
                  based on your system settings.` :
                  ""
                }
              `}
            />
            <HelpToggle />
          </Navbar>
        </Container>

        <hr className={styles.divider} />

      </div>

    </Fragment>
  );
}
