import {
  IAnalyticsPushNotificationDatastore,
  AnalyticsPushNotificationDto,
  analyticsPushNotificationQueryType,
  analyticsPushNotificationQueryExpand
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class AnalyticsPushNotificationDatastoreRestClient
  implements IAnalyticsPushNotificationDatastore {

  public addAsync(userDto: AnalyticsPushNotificationDto) {
    return Promise.reject("Not implemented");
  }

  public findAsync(query: analyticsPushNotificationQueryType) {
    return new Promise<{
      dtos: AnalyticsPushNotificationDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: AnalyticsPushNotificationDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              `${config.apiServer}/analytics-push-notifications`,
              query
            )
          );

          response.dtos = response.dtos.map(
            (dto) => (new AnalyticsPushNotificationDto(dto))
          );
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number, expand?: analyticsPushNotificationQueryExpand) {
    return Promise.reject("Not implemented");
  }

  public removeAsync(id: number) {
    return Promise.reject("Not implemented");
  }

  public updateAsync(userDto: AnalyticsPushNotificationDto) {
    return Promise.reject("Not implemented");
  }
}
