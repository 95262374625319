import {
  CustomerProfileDto,
  customerProfileQueryType,
  ICustomerProfileDatastore
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class CustomerProfileDatastoreRestClient
  implements ICustomerProfileDatastore {

  public addAsync(customerProfileDto: CustomerProfileDto) {
    return new Promise<CustomerProfileDto>(async (resolve, reject) => {
      try {
        const newCustomerProfileDto = await request<CustomerProfileDto>(
          prepareUrl(`${config.apiServer}/customer-profiles`),
          {
            body: customerProfileDto,
            method: "POST"
          }
        );

        resolve(new CustomerProfileDto(newCustomerProfileDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public findAsync(query: customerProfileQueryType) {
    return new Promise<{
      dtos: CustomerProfileDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: CustomerProfileDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              `${config.apiServer}/customer-profiles`,
              query
            )
          );

          response.dtos = response.dtos.map(
            (dto) => (new CustomerProfileDto(dto))
          );
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number) {
    return new Promise<CustomerProfileDto>(async (resolve, reject) => {
      try {
        const customerProfileDto = await request<CustomerProfileDto>(
          prepareUrl(
            `${config.apiServer}/customer-profiles/${id}`
          )
        );
        resolve(new CustomerProfileDto(customerProfileDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public removeAsync(id: number) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await request(
          prepareUrl(
            `${config.apiServer}/customer-profiles/${id}`
          ),
          { method: "DELETE" }
        );

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAsync(customerProfileDto: CustomerProfileDto) {
    return new Promise<CustomerProfileDto>(async (resolve, reject) => {
      try {
        const updatedCustomerProfileDto = await request<CustomerProfileDto>(
          prepareUrl(
            `${config.apiServer}/customer-profiles/${customerProfileDto.id}`
          ),
          {
            body: customerProfileDto,
            method: "PATCH"
          }
        );

        resolve(new CustomerProfileDto(updatedCustomerProfileDto));
      } catch (error) {
        reject(error);
      }
    });
  }
}
