import {
  AppDto,
  appQueryType,
  IAppDatastore
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";
import config from "../config.json";

export class AppDatastoreRestClient implements IAppDatastore {
  public addAsync(appDto: AppDto) {
    return new Promise<AppDto>(async (resolve, reject) => {
      try {
        const newAppDto = await request<AppDto>(
          prepareUrl(`${config.apiServer}/administrators/apps`),
          {
            body: appDto,
            method: "POST"
          }
        );

        resolve(new AppDto(newAppDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public findAsync(query: appQueryType) {
    return new Promise<{
      dtos: AppDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: AppDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              `${config.apiServer}/administrators/apps`,
              query
            )
          );

          response.dtos = response.dtos.map((dto) => (new AppDto(dto)));
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number) {
    return new Promise<AppDto>(async (resolve, reject) => {
      try {
        const appDto = await request<AppDto>(
          prepareUrl(
            `${config.apiServer}/administrators/apps/${id}`
          )
        );
        resolve(new AppDto(appDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public removeAsync(id: number) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await request(
          prepareUrl(
            `${config.apiServer}/administrators/apps/${id}`
          ),
          { method: "DELETE" }
        );

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAsync(appDto: AppDto) {
    return new Promise<AppDto>(async (resolve, reject) => {
      try {
        const updatedAppDto = await request<AppDto>(
          prepareUrl(`${config.apiServer}/administrators/apps/${appDto.id}`),
          {
            body: appDto,
            method: "PATCH"
          }
        );

        resolve(new AppDto(updatedAppDto));
      } catch (error) {
        reject(error);
      }
    });
  }
}
