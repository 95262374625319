// NOTE(jc): To add new font also change: ./index.ts, ./FontAwesomeExtendedIcon.tsx, ../common/fonts.ts

import {
  IconDefinition
} from "@fortawesome/fontawesome-common-types";

export const prefix = "fa-extended";
export const iconName = "between";
export const width = 640;
export const height = 512;
export const ligatures: string[] = [];
// This is being used only for SVG so unicode is not needed.
export const unicode = "";
export const svgPathData = "m16 32c-8.837 0-16 7.163-16 16v416c0 8.837 7.163 16 16 16h40c8.837 0 16-7.163 16-16v-416c0-8.837-7.163-16-16-16zm568 0c-8.837 0-16 7.163-16 16v416c0 8.837 7.163 16 16 16h40c8.837 0 16-7.163 16-16v-416c0-8.837-7.163-16-16-16zm-385.57 113.9c-5.7045 0.14293-11.476 2.3453-16.201 7.0703l-86.059 86.059c-9.373 9.373-9.373 24.568 0 33.941l86.059 86.059c15.12 15.119 40.971 4.4113 40.971-16.971v-46.059h195.88v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.568 0-33.941l-86.059-86.059c-4.7247-4.725-10.497-6.9274-16.201-7.0703-12.55-0.31445-24.77 9.3409-24.77 24.041v46.059h-195.88v-46.059c0-14.7-12.22-24.355-24.77-24.041z";

export const definition = {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
} as unknown as IconDefinition;

export const faBetween = definition;
