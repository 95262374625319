import {
  AnalyticsNewDownloadDto,
  IAnalyticsNewDownloadDatastore,
  analyticsNewDownloadQueryType
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class AnalyticsNewDownloadDatastoreRestClient
  implements IAnalyticsNewDownloadDatastore {

  public addAsync(analyticsNewDownloadDto: AnalyticsNewDownloadDto) {
    return Promise.reject("Not implemented");
  }

  public findAsync(query: analyticsNewDownloadQueryType) {
    return new Promise<{
      dtos: AnalyticsNewDownloadDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: AnalyticsNewDownloadDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              `${config.apiServer}/analytics-new-downloads`,
              query
            )
          );

          response.dtos = response.dtos.map(
            (dto) => (new AnalyticsNewDownloadDto(dto))
          );
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number) {
    return Promise.reject("Not implemented");
  }

  public updateAsync(analyticsNewDownloadDto: AnalyticsNewDownloadDto) {
    return Promise.reject("Not implemented");
  }
}
