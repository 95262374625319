import {
  ILocationDatastore,
  LocationDto,
  locationQueryType
} from "dev-center-lib";

import { prepareUrl, request } from "../common/apiClient";

import config from "../config.json";

export class LocationDatastoreRestClient implements ILocationDatastore {
  public addAsync(locationDto: LocationDto) {
    return new Promise<LocationDto>(async (resolve, reject) => {
      try {
        const newLocationDto = await request<LocationDto>(
          prepareUrl(`${config.apiServer}/administrators/locations`),
          {
            body: locationDto,
            method: "POST"
          }
        );

        resolve(new LocationDto(newLocationDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public findAsync(query: locationQueryType) {
    return new Promise<{
      dtos: LocationDto[],
      objectsPerPage: number,
      pageNumber: number,
      totalObjectCount: number,
      totalPageCount: number
    }>(
      async (resolve, reject) => {
        try {
          const response = await request<{
            dtos: LocationDto[],
            objectsPerPage: number,
            pageNumber: number,
            totalObjectCount: number,
            totalPageCount: number
          }>(
            prepareUrl(
              `${config.apiServer}/administrators/locations`,
              query
            )
          );

          response.dtos = response.dtos.map((dto) => (new LocationDto(dto)));
          resolve({ ...response });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  public getAsync(id: number) {
    return new Promise<LocationDto>(async (resolve, reject) => {
      try {
        const locationDto = await request<LocationDto>(
          prepareUrl(
            `${config.apiServer}/administrators/locations/${id}`
          )
        );
        resolve(new LocationDto(locationDto));
      } catch (error) {
        reject(error);
      }
    });
  }

  public removeAsync(id: number) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await request(
          prepareUrl(
            `${config.apiServer}/administrators/locations/${id}`
          ),
          { method: "DELETE" }
        );

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAsync(locationDto: LocationDto) {
    return new Promise<LocationDto>(async (resolve, reject) => {
      try {
        const updatedLocationDto = await request<LocationDto>(
          prepareUrl(
            `${config.apiServer}/administrators/locations/${locationDto.id}`
          ),
          {
            body: locationDto,
            method: "PATCH"
          }
        );

        resolve(new LocationDto(updatedLocationDto));
      } catch (error) {
        reject(error);
      }
    });
  }
}
