import React from "react";
import { Alert } from "reactstrap";

import {
  FontAwesomeExtendedIcon
} from "../font-awesome-extended/FontAwesomeExtendedIcon";

export function LoadingMessage(props: { visible?: boolean }) {
  if (!props.visible) { return null; }

  return (
    <Alert
      tag="aside"
      color="info"
      className="d-flex justify-content-center align-items-center"
    >
      <FontAwesomeExtendedIcon
        icon="spinner"
        size="2x"
        fixedWidth={true}
        spin={true}
      />
    </Alert>
  );
}
